import React, { Component } from 'react'
import { aboutDataJSON } from '../../properties/translations'
import { withTranslation } from 'react-i18next'
class AboutUs extends Component {
  state = {
    data: {},
    aboutData: [],
  }
  componentDidMount() {
    this.setState({
      data: aboutDataJSON,
    })
  }
  render() {
    const { t } = this.props
    return (
      <section id="aboutUs" className="section about-app-area ptb_100">
        <div className="shapes-container">
          <div className="shape-1" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6">
              {/* About Text */}
              <div className="about-text">
                {/* Headings */}
                <div className="headings d-flex align-items-center mb-4">
                  <span className="text-uppercase d-none d-sm-block">
                    {t('aboutDataJSON.headingSpan')}
                  </span>
                  <h2 className="text-capitalize">
                    {' '}
                    {t('aboutDataJSON.heading')}
                  </h2>
                </div>
                <p className="my-3"> {t('aboutDataJSON.headingText')}</p>
                <p className="d-none d-sm-block my-3">
                  {t('aboutDataJSON.headingTextTwo')}
                </p>
                <p className="d-none d-sm-block my-3">
                  {t('aboutDataJSON.headingTextThree')}
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              {/* About Thumb */}
              <div className="about-thumb text-center d-none d-lg-block">
                <img src={this.state.data.aboutThumb} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation('translations')(AboutUs)
