import React, { Component } from 'react'
import { servicesData } from '../../properties/translations'
import { withTranslation } from 'react-i18next'

class Services extends Component {
  state = {
    data: {},
    featuresData: [],
  }
  componentDidMount() {
    this.setState({
      data: servicesData,
      featuresData: servicesData.featuresData,
    })
  }
  render() {
    const { t } = this.props
    return (
      <section
        id="services"
        className="section features-area ptb_100 ptb_services_margin"
      >
        <div className="shapes-container">
          <div className="shape bg-shape" />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 col-lg-6">
              <div className="features-text">
                {/* Headings */}
                <div className="headings d-flex align-items-center mb-4">
                  <span className="text-uppercase d-none d-sm-block">
                    {t('servicesData.headingSpan')}
                  </span>
                  <h2 className="text-capitalize">
                    {t('servicesData.heading')}
                  </h2>
                </div>
                <p className="my-3"> {t('servicesData.headingText')}</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 offset-lg-1">
              {/* Featured Items */}
              <div className="featured-items">
                <ul>
                  {this.state.featuresData.map((item, idx) => {
                    return (
                      <li key={`fo_${idx}`}>
                        {/* Single Features Item */}
                        <div className="single-features media p-3">
                          {/* Features Title */}
                          <div className="features-title mr-3">
                            <img src={item.featureImage} alt="" />
                          </div>
                          {/* Features Text */}
                          <div className="features-text media-body">
                            <h3>
                              {t(
                                `servicesData.featuresData.${idx}.featuretitle`
                              )}
                            </h3>
                            <p>
                              {t(
                                `servicesData.featuresData.${idx}.featureText`
                              )}
                            </p>
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation('translations')(Services)
