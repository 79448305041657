import React from 'react'
import privacyPolicy from './en'
import './style.css'
class Policy extends React.Component {
  render() {
    return (
       <div className="privacy-div">
        <h2>{privacyPolicy.title}</h2>
        <p className="privacy-text">{privacyPolicy.policyDetailsSectionOne}</p>
        <h3>{privacyPolicy.policyDetailTitleTwo}</h3>
        <p className="privacy-text">{privacyPolicy.policyDetailsSectionTwo}</p>
        <p className="privacy-text">{privacyPolicy.policyDetailsSectionThree}</p>
        <p className="privacy-text">{privacyPolicy.policyDetailsSectionFour}</p>
        <p className="privacy-text">{privacyPolicy.policyDetailsSectionFive}</p>
        <p className="privacy-text">{privacyPolicy.policyDetailsSectionFive}</p>
        <h3>{privacyPolicy.policyTitleThree}</h3>
        <p className="privacy-text">{privacyPolicy.policyDetailsSix}</p>
        <h3>{privacyPolicy.policyTitleFour}</h3>
        <p className="privacy-text">{privacyPolicy.policyDetailsSeven}</p>
        <h3>{privacyPolicy.policyTitleFive}</h3>
        <p className="privacy-text">{privacyPolicy.policyDetailsEight}</p>
        <h3>{privacyPolicy.policyTitleSix}</h3>
        <p className="privacy-text">{privacyPolicy.policyDetailsNine}</p>
        <h3>{privacyPolicy.policyTitleSeven}</h3> 
        <p className="privacy-text">{privacyPolicy.policyDetailsTen}</p>
       </div>
    )
  }
}

export default Policy
