import React, { Component } from 'react'
// import { headerSectionData } from '../../properties/translations'
import { withTranslation } from 'react-i18next'
import LanguageContext from '../../configureContext'
class Header extends Component {
  static contextType = LanguageContext
  constructor(props) {
    super(props)
    this.state = {
      newLang: 'en',
    }
  }

  componentDidMount() {
    const { activeLanguage } = this.context.state
    this.setState({ newLang: activeLanguage })
  }

  handleChange = (event) => {
    const { setActiveLanguage } = this.context
    let newlang = event.target.value
    this.setState({ newLang: newlang })
    setActiveLanguage(newlang)
    this.props.i18n.changeLanguage(newlang)
  }

  render() {
    const { t } = this.props

    return (
      <header className="section header-area">
        <div id="appo-header" className="main-header-area nav-white">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              {/* Logo */}
              <a className="navbar-brand" href="/#">
                <img
                  className="logo"
                  src={this.props.imageData}
                  alt="Artics-logo"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#appo-menu"
              >
                <span className="navbar-toggler-icon" />
              </button>
              {/* Appo Menu */}
              <div className="collapse navbar-collapse" id="appo-menu">
                {/* Header Items */}
                <ul className="navbar-nav header-items ml-auto">
                  <li className="nav-item active dropdown">
                    <a
                      className="nav-link"
                      href="/#"
                      id="navbarDropdown"
                      role="button"
                      onClick={this.props.scrollToTop}
                    >
                      {t('headerSectionData.navBarItemTitles.Home')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link scroll" href="#aboutUs">
                      {t('headerSectionData.navBarItemTitles.AboutUs')}
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link scroll" href="#screenshots">
                      {t('headerSectionData.navBarItemTitles.Projects')}
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link scroll" href="#services">
                      {t('headerSectionData.navBarItemTitles.Services')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link scroll" href="#footer">
                      {t('headerSectionData.navBarItemTitles.Contact')}
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    )
  }
}

export default withTranslation('translations')(Header)
