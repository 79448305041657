import React, { Component } from 'react'
import $ from 'jquery'
import ReactNotification, { store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import ScrollupSection from '../components/scrollupSection/scrollUp'
import Header from '../components/headerSection'
import WelcomeSection from '../components/welcomeSection'
// import BrandingSection from '../components/brandingSection/branding'
import AboutSection from '../components/aboutSection'
import WorkSection from '../components/workSection'
// import FeatureSection from '../components/featureSection'
import ServicesSection from '../components/ServicesSection'
// import ProjectSection from '../components/projectsSection'
// import ProjectSectionTitle from '../components/projectSectionTitle'
// import PricingSection from '../components/pricingSection/pricingOne'
// import ReviewSection from '../components/reviewSection/reviewOne'
// import TeamSection from '../components/teamSection'
// import SubscribeSection from '../components/subscribeSection/subscribeOne'
// import DownloadSection from '../components/downloadSection/downloadOne'
// import BlogSection from '../components/blogSection/blogOne'
import ContactSection from '../components/contactSection/contact'
import FooterSection from '../components/footerSection/footer'
import { BrowserRouter as Router, Switch, Route  } from 'react-router-dom'
import Policy from '../components/PrivacyPolicy'

class ArticsTheme extends Component {
  constructor(props) {
    super(props)
    this.scrollToTop = this.scrollToTop.bind(this)
    this.scrollToBottom = this.scrollToBottom.bind(this)
  }
  scrollToTop() {
    $('html, body').animate({ scrollTop: 0 }, 1000)
  }
  scrollToBottom() {
    $('html, body').animate({ scrollTop: $('#contact').offset().top }, 2000)
  }
  render() {
    return (
      <Router>
        <Switch>
          <div className="paris">
            <ScrollupSection />
            <div className="all-area">
              <ReactNotification />
              <Header
                scrollToTop={this.scrollToTop}
                imageData={'/img/artics-transparent-logo.png'}
              />
              <Route exact path="/">
                <WelcomeSection scrollToBottom={this.scrollToBottom} />
                <AboutSection />
                <WorkSection />
                <ServicesSection />
                {/* <ProjectSectionTitle />
                <ProjectSection /> */}

                {/* <SubscribeSection /> */}
                <div style={{marginTop: '240px'}}>
                <ContactSection notificationStore={store} />
                </div>
              </Route>
              <Route path="/privacy-policy">
                <Policy />
              </Route>
              <FooterSection />
            </div>
          </div>
        </Switch>
      </Router>
    )
  }
}

export default ArticsTheme
