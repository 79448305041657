import React, { Component } from 'react'
import ReactGA from 'react-ga'
import ArticsTheme from './themes/artics-theme'
import { LanguageContextProvider } from './configureContext'

class App extends Component {
  constructor(props) {
    super(props)
    ReactGA.initialize('UA-173160718-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
  render() {
    return (
      <LanguageContextProvider>
        <ArticsTheme />
      </LanguageContextProvider>
    )
  }
}

export default App
