module.exports = {
  title: 'Privacy policy',
  policyDetailsSectionOne: 
  'This privacy policy describes how our site (the “Site”, “we” or “us”) collects, uses, shares and protects the personal information that we collect through this Application. This policy also applies to mobile applications that we develop for use with our services and Team specific pages on the Site, and references to this “Site”, “we” or “us” is intended to also include these mobile applications. Please read below to learn more about our information practices. By using this Site, you agree to these practices',
  policyDetailTitleTwo: 'How We Collect Information',
  policyDetailsSectionTwo: 'Like many other websites, we may record information that your web browser routinely shares, such as your browser type, browser language, software and hardware attributes, the date and time of your visit, the web page from which you came, your Internet Protocol address and the geographic location associated with that address, the pages on this Site that you visit and the time you spent on those pages. This will generally be anonymous data that we collect on an aggregate basis. We may also use Google Analytics or a similar service to gather statistical information about the visitors to this Site and how they use the Site. This, also, is done on an anonymous basis. We will not try to associate anonymous data with your personally identifiable data.',
  policyDetailsSectionThree: 'Other than basic information that we need to establish your account, it will be up to you to decide how much information to share as part of your profile. We encourage you to think carefully about the information that you share and we recommend that you guard your identity and your sensitive information. Of course, you can review and revise your profile at any time.',
  policySectionFour: 'If you have created an account, we will also use persistent cookies that remain on your computer’s hard-drive between visits, so that when you return to our Application we can remember who you are and your preferences.',
  policySectionFive: 'We may allow business partners who provide services to our Site to place cookies on your computer that assist us in analyzing usage data. We do not allow these business partners to collect your personal information from our website except as may be necessary for the services that they provide.',
  policyTitleThree: 'What We Do With The Information We Collect',
  policyDetailsSix: 'We will generally use the information that we collect to provide our services, to monitor and analyze visitor activity on our Application, promote and support our services, and develop a knowledge base regarding our Application users. As detailed below, certain information that you provide may be available to visitors to the Site, and some information will be shared between Customers and Teams.',
  policyTitleFour: 'Your Contact Information',
  policyDetailsSeven: 'When you provide us with your contact information, we will use that information to communicate with you about your use of our service. If you agree, we may also use your e-mail address to send you a newsletter or other information about our services or about other products or services in which you may be interested. You may change your preferences at any time, though you will not be able to opt out of messages relating to your use of our service.', 
  policyTitleFive: 'Anonymous Data',
  policyDetailsEight: 'We use the anonymous data that we collect on an aggregate basis to gain a better understanding of the visitors to our Site and to improve our website and product offerings. We reserve the right to license or sell this aggregated information to third parties for industry analysis, demographic profiling and other purposes, but this information will not contain your individually identifiable personal information.',
  policyTitleSix: 'Data Integrity And Retention',
  policyDetailsNine: 'We use the information that we collect about you only for the purposes for which it is collected and consistent with this policy. We keep information provided to us for as long as we believe necessary for our business purposes and as permitted by applicable law.',
  policyTitleSeven: 'Changes To This Policy',
  policyDetailsTen: 'Our business and the laws that regulate us change from time to time, and we reserve the right to change this policy. If we do change this policy, we will post the revised version on this Application. If we propose to change our policy in a way that would permit us to make additional uses of information that we had previously collected about you, we will provide you with a meaningful way to opt out of those additional uses. Artics Inc. is located and operates in the state of California, United States of America. Depending on where you live, the information that you provide and that this Application collects may be stored on servers that are outside of your state, province, country or other governmental jurisdiction, and the privacy laws that apply may not be as protective as those in your home jurisdiction. If you are located outside of the United States and choose to provide information to us, the Artics Inc. might transfer personal information to other countries and process it there. By using this application, you consent to this transfer and processing of data. Children While our Site is available for all to come visit, you must be an adult to register on our website and use our services. We will not knowingly collect information about children under the age of 18. If you are a parent who believes that we have collected information about a child under age 18 or 13, please contact us at support@artics.io with your child’s name and address, and we will be happy to delete the information we have about your child from our records in accordance with https://en.wikipedia.org/wiki/Children%27s_Online_Privacy_Protection_Act. This Policy Is An AgreementWhen you visit this Site, you are accepting the practices described in this Privacy Policy.',
  lastUpdated: 'Last updated August 01, 2020',
}
