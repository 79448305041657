import React, { createContext, Component } from 'react'

const LanguageContext = createContext()

class LanguageContextProvider extends Component {
  state = {
    activeLanguage: 'en',
  }
  render() {
    return (
      <LanguageContext.Provider
        value={{
          state: this.state,
          setActiveLanguage: (value) => {
            this.setState({ activeLanguage: value })
          },
        }}
      >
        {this.props.children}
      </LanguageContext.Provider>
    )
  }
}

export { LanguageContextProvider }

export default LanguageContext
