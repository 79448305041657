import React, { Component } from 'react'
import { worksSection } from '../../properties/translations'
import { withTranslation } from 'react-i18next'
import LanguageContext from '../../configureContext'

const imgData = worksSection.imgData

const workData = worksSection.workData

class workSection extends Component {
  state = {
    data: [],
    workData: [],
  }
  componentDidMount() {
    this.setState({
      data: imgData,
      workData: workData,
    })
  }
  render() {
    const { t } = this.props
    return (
      <LanguageContext.Consumer>
        {(props) => {
          return (
            <section className="section work-area ptb_100">
              {/* Work Slider Wrapper */}
              <div className="work-wrapper d-none d-md-block">
                <div className="work-slider-wrapper" data-aos="zoom-in">
                  {/* Work Slider */}
                  <ul className="work-slider owl-carousel">
                    {this.state.data.map((item, idx) => {
                      return (
                        <li key={`wd_${idx}`} className="slide-item">
                          <img src={item.image} alt="" />
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
              <div className="container">
                {/* Work Content */}
                <div
                  className="row justify-content-end justify-content-lg-between work-content"
                  id="work-slider-pager"
                >
                  {this.state.workData.map((item, idx) => {
                    return (
                      <div key={`wdo_${idx}`} className="col-12 col-sm-6">
                        <a href="/#" className={item.linkClass}>
                          {/* Single Work */}
                          <div className="single-work d-inline-block text-center p-4">
                            <h3 className="mb-2">
                              {t(`worksSection.workData.${idx}.title`)}
                            </h3>

                            <p>{t(`worksSection.workData.${idx}.text`)}</p>
                          </div>
                        </a>
                      </div>
                    )
                  })}
                </div>
              </div>
            </section>
          )
        }}
      </LanguageContext.Consumer>
    )
  }
}

export default withTranslation('translations')(workSection)
